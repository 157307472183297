<template>
    <div
        :class="[
          'd-flex justify-content-center align-items-center',
          !searching && 'cursor-pointer toggle-active',
          'search-container',
          'text-brand-muted',
          !searching && 'p-2',
          'bg-brand-body-secondary',
          'shadow-sm'
        ]"
        @click.stop="searching = !searching"
      >
        <div
          :class="[
            'position-relative mt-1 p-1 bg-brand-body-secondary shadow search-field-container',
            !searching && 'd-none'
          ]"
        >
          <div>
            <SearchToolMiniInput
                v-if="searching"
                @textVal="updateQuery"
                @click.stop="searching = true"
                @keydown.esc="searching = false"
            />
          </div>
          <div class="d-flex justify-content-center align-items-center search-close-icon">
            <Icon class="cursor-pointer" icon-name="cancel" :icon-weight="2.5"/>
          </div>
        </div>

        <Icon v-if="!searching" icon-name="search" :icon-weight="2.5"/>
      </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';
import SearchToolMiniInput from '@/components/shared/SearchToolMiniInput.vue';

export default {
    name: "SearchToolMini",
    components: {
      Icon,
      SearchToolMiniInput
    },
    emits: [
        'searching',
        'query'
    ],
    data: function () {
        return {
            searching: false
        }
    },
    watch: {
        searching: function (newVal) {
            this.$emit('searching', newVal);
        }
    },
    methods: {
        updateQuery: function (newVal) {
            this.$emit('query', newVal);
        }
    }
}
</script>

<style scoped lang="scss">
.search-container {
  $icon-size: 30px;
  
  position: absolute;
  top: 0;
  left: calc(50% - #{$icon-size * 1.61803 / 2});
  font-size: $icon-size * 0.75;
  width: $icon-size * 1.61803;
  min-height: $icon-size;
  border-radius: 0 0 $icon-size $icon-size;

  input {
    border: none;
    border-radius: $icon-size;
    height: $icon-size;
    width: 220px;
    font-size: 61.803%;
    color: #2F2F2F;

    &::placeholder {
      font-style: italic;
      color: #a2a2a2;
    }
  }

  .search-field-container {
    line-height: 0;
    border-radius: $icon-size;
  }

  .search-close-icon {
    height: $icon-size;
    width: $icon-size;
    position: absolute;
    top: calc(50% - (#{$icon-size / 2}));
    right: $icon-size/2;
    font-size: $icon-size * 0.55;
    border-radius: $icon-size;
  }
}
</style>
