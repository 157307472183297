<template>
  <div class="col-6 col-md-4 col-lg-3 col-xxl-3 p-1 p-sm-2">
    <router-link
      :class="[
        'w-100 h-100 px-3 py-2 bg-brand-body-secondary',
        'd-flex flex-xl-row',
        'justify-content-center align-items-center',
        'make-card'
      ]"
      :to="link || '#'"
    >
      <div class="d-none d-sm-flex justify-content-center align-items-center make-option py-2 px-3">
        <img
          :src="makeLogo"
          :alt="`Logo ${makeName}`"
          class="make-logo d-none user-select-none"
          onerror="this.className='d-none'; this.src='https://vvs.autosyncstudio.com/static/icons/Autosync-logo-grey.png'; this.className='logo-opacity'"
          onload="this.classList.remove('d-none')"
        >
        <div class="d-none preloader">
          <DotsFlashingLoader/>
        </div>
      </div>
      <div class="text-start text-sm-center fw-regular make-option make-name">
        {{makeName}}
      </div>
    </router-link>
  </div>
</template>

<script>
import DotsFlashingLoader from '@/components/shared/DotsFlashingLoader';

export default {
  name: 'MakeCard',
  components: {
    DotsFlashingLoader
  },
  props: {
    makeName: String,
    makeLogo: String,
    loaded: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

a {
  text-decoration: none;

  &:visited, &:link {
    color: #000;
  }
}

.make-card {
  &:hover {
    @extend .bg-brand-light-gray;
    cursor: pointer;

    & > .make-name {
      font-weight: 600;
      transition: .1s;
    }
  }
}

.make-card, .make-option {
  border-radius: 8px;
}

.make-logo {
  max-height: 75px;
  max-width: 50px;
}

.logo-opacity {
  height: 50%;
  width: 50%;
  opacity: .05;
}

.make-name {
  flex-grow: 1;
  min-height: 20px;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px) {
  .make-name {
    width: 100px;
    min-height: 15px;
    font-size: 12px;
    margin-left: 0;
    line-height: 1.5;
  }
}
</style>
