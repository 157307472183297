<template>
  <Container navbar small back-to="/home" message="select your make">
    <section
      :class="[
        'col-12',
        'px-0 py-3',
        'text-brand-text',
        'position-relative',
        searching && 'pt-5 pt-sm-3'
      ]"
    >
      <SearchToolMini style="z-index: 1" @query="updateSearch" @searching="updateSearchStatus"/>

      <div class="container-fluid p-2 px-md-4 py-md-0 m-0">
        <div v-if="loaded" class="row justify-content-start aling-content-center m-0 m-md-3">
          <MakeCard
            v-for="(make, key) in makes"
            :key="key"
            :make-name="make.Make"
            :make-logo="`${logoUrl}${make.Logo}`"
            :link="`/vehicles/make/${encodeURIComponent(make.Make)}`"
          />
        </div>
        
        <div v-else class="row justify-content-start aling-content-center m-0 m-md-3">
          <MakeCard
            v-for="(item, key) in makes"
            :key="key"
            :make-name="null"
            :make-logo="null"
          />
        </div>
      </div>
    </section>
  </Container>
</template>
<script>
import Container from "@/components/Container.vue";
import MakeCard from "@/components/vehicles/MakeCard.vue";
import apiRequest from "@/utils/apiRequest";
import SearchToolMini from '@/components/shared/SearchToolMini.vue';

export default {
  name: "Vehicles",
  components: {
    Container,
    MakeCard,
    SearchToolMini
  },
  data: function() {
    return {
      loaded: false,
      logoUrl: null,
      makes: new Array(12).fill(),
      originalMakes: [],
      searchMake: null,
      searching: false
    }
  },
  watch: {
    searching: function (newVal) {
      if (!newVal) {
        this.makes = this.originalMakes;
      } else {
        this.originalMakes = this.makes;
      }
    },
    searchMake: function (newVal) {
      if (newVal) {
        this.makes = this.originalMakes.filter(make => make.Make.toUpperCase().trim().includes(newVal.toUpperCase().trim()));
      } else {
        this.searchMake = null;
        this.makes = this.originalMakes;
      }
    }
  },
  methods: {
    updateSearch: function (val) {
      this.searchMake = val;
    },
    updateSearchStatus: function (val) {
      this.searching = val;
    }
  },
  beforeMount: async function() {
    if (!('baseUrl' in this.$store.getters.vehiclesMakes)) {
      let makes = await apiRequest('/vehicles/makes?f-types=Truck,Van,Car,SUV&i-logos&p-size=500');
      if (makes) {
        this.makes = makes.Makes || null;
        this.logoUrl = makes.LogoUrlBase || null;
        this.loaded = true;

        this.$store.commit('vehiclesMakes', {baseUrl: this.logoUrl, makes: this.makes});
      }
    } else {
      const storedMakes = this.$store.getters.vehiclesMakes;
      if (storedMakes) {
        this.makes = storedMakes.makes || null;
        this.logoUrl = storedMakes.baseUrl || null;
        this.loaded = true;
      }
    }
  }
}
</script>
