<template>
    <input ref="searchInput" type="text" v-model="searchMake" class="text-center text-muted p-0 m-0 ps-4 pe-5" placeholder="Search a make">
</template>

<script>
export default {
    name: 'SearchToolMiniInput',
    emits: [
        'textVal'
    ],
    data: function () {
        return {
            searchMake: null
        }
    },
    watch: {
        searchMake: function (newVal) {
            this.$emit('textVal', newVal)
        }
    },
    mounted: function () {
        this.$refs.searchInput.focus();
    }
}
</script>
